import React from 'react'

export interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  maxWidth?: string;
  height?: string;
  id?: string;
  borderRadius?: string;
  boxShadow?: string;
  type?: 'button' | 'submit' | 'reset';
}

export function Button({
  onClick,
  children,
  className,
  disabled,
  id,
  maxWidth,
  height,
  borderRadius,
  boxShadow,
  type
}: Props) {
  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      className={`focus:outline-none text-textSmall ${className}`}
      style={{
        opacity: `${disabled ? '0.7' : ''}`,
        width: maxWidth,
        height,
        borderRadius,
        boxShadow
      }}
      disabled={disabled}
    >
      {children}
    </button>
  )
}