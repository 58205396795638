import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import moment from 'moment'
import Layout from "../components/layout"
import { Divider } from "../components/atoms/lines/Divider";
import './post.scss'
import { Button } from "../components/atoms/buttons/Button";

const Post = ({ data }) => {
  const post = data.ghostPost
  const author = post.authors.map((item) => item.slug)
  const tag = (
    post.tags.map((item, index) =>
    <p key={item.id} className="inline-block text-textSmaller text-darkPurpleColor">{item.slug}{index < post.tags.length - 1 ? ',\u00A0' : ''}</p>)
  )
  function backToPosts() {
    location.href = '/';
  }
  return (
    <Layout>
      <GatsbySeo
        title={post.meta_title}
        description={post.meta_description}
        canonical='https://blog.theia.com.br/'
      />
      <div className="wrapper-section post-ghost">
        <h2 className="item1 text-mediumPurpleColor text-titleMedium lg:text-titleExtraLarge font-medium text-center">Conteúdo do blog da Theia</h2>
        <article className="post item2">
          <Button
            className="underline text-textSmaller text-greyColor mb-4"
            onClick={backToPosts}
          >
            Voltar
          </Button>
          <h1 className="text-titleMedium lg:text-titleLarge text-darkGreyColor font-medium">{post.title}</h1>
          <Divider
              maxWidth="86px"
              border="1px solid #9A4178"
              className="mt-6 mb-2"
            />
          <div className="flex post-info flex-col lg:flex-row lg:items-center">
            <p className="text-textSmaller text-mediumGreyColor">{moment(post.published_at).format("DD/MM/YYYY")}</p>
            <p className="text-textSmaller text-mediumGreyColor author">{author} Theia</p>
            <p>{tag}</p>
          </div>
         
          {post.feature_image ? (
            <img src={post.feature_image} alt={post.title} />
          ) : null}
          <section className="post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
          
        </article>
      </div>
    </Layout>
  )
}
export default Post
export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      published_at
      meta_title
      meta_description
      tags {
        id
        slug
      }
      primary_tag {
        id
        slug
      }
      authors {
        id
        slug
      }
    }
  }
`